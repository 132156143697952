import { CssBaseline, Divider, Grid, Paper, styled, ThemeProvider } from '@mui/material';
import theme from '../theme';
import ChatContainer from './Chat/ChatContainer';
import PatientListContainer from './PatientList/PatientListContainer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { de } from 'date-fns/locale';
import ViewerContainer from './FileViewer/FileViewerContainer';
import * as Sentry from '@sentry/react';
import { useURLSearchParamsForAppConfiguration } from '../hooks/useURLSearchParamsForAppConfiguration';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { heurekaRequestActionBundles } from '../store/heureka/actions';
import SidebarContainer from './Sidebar/SidebarContainer';

const AppPaper = styled(Paper)`
  display: grid;
  min-height: 300px;
  height: 100vh;
  max-width: 1750px;
  background: white;
  ${theme.breakpoints.down('xl')} {
    box-shadow: none;
    border-radius: 0;
  }
  ${theme.breakpoints.up('xl')} {
    margin: 20px auto;
    height: calc(100vh - 40px);
    width: calc(100% - 40px);
  }
`;

function App() {
  const { hidePatientList, code, state } = useURLSearchParamsForAppConfiguration();
  const dispatch = useDispatch();
  useEffect(() => {
    if (code && state === localStorage.getItem('state')) {
      dispatch(heurekaRequestActionBundles.connectHeureka.sendRequest(code));
    }
  }, [code, state, dispatch]);

  return (
    <LocalizationProvider adapterLocale={de} dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppPaper>
          <Grid container minHeight={0}>
            {!hidePatientList && (
              <Grid container item xs={3} lg={2.6} xl={2.4}>
                <PatientListContainer />
              </Grid>
            )}
            <Divider orientation="vertical" />
            <Grid item xs display="grid" height="100%" bgcolor={theme.palette.grey[200]}>
              <ChatContainer />
              <ViewerContainer />
            </Grid>
            <Divider orientation="vertical" />
            <Grid item xs={3} lg={2.6} xl={2.4}>
              <SidebarContainer />
            </Grid>
          </Grid>
        </AppPaper>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default Sentry.withProfiler(App);
