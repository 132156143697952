import { Avatar, Box, Button, styled, Typography } from '@mui/material';

const BlackOutlinedButton = styled(Button)`
  border-radius: 10px;
  color: black;
  border-color: black;
`;

const ColumnBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export default function HeurekaButton({ connectHeureka }: { connectHeureka: (code?: string) => void }) {
  return (
    <BlackOutlinedButton
      onClick={() => {
        connectHeureka();
      }}
      variant="outlined"
      startIcon={<Avatar src="/icons/logomark-large.png"></Avatar>}>
      <ColumnBox>
        <Typography variant="caption" align="left">
          Verbinden mit
        </Typography>
        <Typography variant="body1" align="left" fontWeight={'bold'}>
          Heureka Health
        </Typography>
      </ColumnBox>
    </BlackOutlinedButton>
  );
}
